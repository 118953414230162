import { Tooltip } from "react-bootstrap";
import { Show } from "../../hooks/Show";

const Header = ({ title, subtitle, actionButtons }) => {
    const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>; 

    const renderBtns = () => {
        if (!actionButtons)
            return null;

        const buttons = actionButtons.map((ele, index) => {
            if (ele.permission) {
                return (
                    <div className="mx-1" key={index}>
                        <Show when={ele.permission}>
                                <button
                                    title={ele.description}
                                    className={`btn ${ele.buttonType} btn-circle`}
                                    type="button"
                                    onClick={ele.onClick}
                                >
                                    <i className={`fas ${ele.buttonIcon}`}></i>
                                </button>
                        </Show>
                    </div>
                )
            } else {
                return (
                    <div className="mx-1" key={index}>
                            <button
                                className={`btn ${ele.buttonType} btn-circle`}
                                type="button"
                                onClick={ele.onClick}
                            >
                                <i className={`fas ${ele.buttonIcon}`}></i>
                            </button>
                    </div>
                )
            }
        });

        return buttons;
    }

    return (<>
        <div className="d-sm-flex align-items-center mb-1">
            <h1 className="h3 mb-0 text-gray-800 mr-3">{title}</h1>
            {
                renderBtns()
            }
        </div>
        <p className="mb-4">{subtitle}</p>
    </>);
}

export default Header;