export const buttonTypes = {
    Primary: 'btn-primary',
    Danger: 'btn-danger',
    Success: 'btn-success',
}

export const buttonIcon = {
    Upload: 'fa-upload',
    UploadFile: 'fa-file-upload',
    DownloadFile: 'fa-file-download',
    Download: 'fa-download',
    Add: 'fa-plus',
    FileExport: 'fa-file-export',
    FileImport: 'fa-file-import',
    FileContract: 'fa-file-contract',
    ClipBoard: 'fa-clipboard-list',
    Refresh: 'fas fa-undo',
    Info: 'fas fa-info',
    List: 'fas fa-list',
    Columns: 'fas fa-columns',
    Edit: 'fas fa-edit',
    
}