import { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Button, Tab, Nav } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { DateTime, IANAZone } from 'luxon';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';

import CustomTooltip from "../../components/CustomTooltip";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import Grid from '../../components/Grid';
import { PermissionContext } from '../../hooks/PermissionContext';
import { getStatusBadgeByCode } from '../../utils/StatusUtils';
import { isNullOrUndefined } from "../../utils/functionsUtils";
import OrderManagementModal from "../../components/Modal/Incidents";



const IncidentsManagement = ({
    reduxGetAddressesIncidences,
    incidences,
    reduxGetShippers,
    shippers,
    reduxGetOperators,
    logisticsOperators,
    reduxGetNovelties,
    novelties,
    reduxManageNovelties,
    successfulManageNoveltiesForIncidents,
    masivNoveltiesManage
}) => {
    const {
        handleSubmit: searchSubmit,
        control: searchControl,
        formState: { errors: searchErrors },
        setValue: setValueSearch,
        getValues: getSearchValue,
        setError: setSearchError,
        clearErrors: clearSearchErrors,
    } = useForm({
        defaultValues: {
            from: null,
            to: null,
            shipper: null,
            operator: null,
            novelty: null,
        }
    });
    const { 
        control,
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues,
        reset,
     } = useForm({
        defaultValues: {
            byTrackings: '1'
        }
    });

    const { hasPermission } = useContext(PermissionContext);

    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData?.idCountry;

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [ disableSubmit, setDisableSubmit ] = useState();

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "4.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    };

    const onSubmit = (data) => {
        const errors = validGuides();
        if(errors.length > 0){
            Swal.fire('Archivo errado', 'Señor Usuario, hay envios duplicadas. Por favor revise los datos ingresadas.', 'error');
            return;
        }
        Swal.fire({
            title: "¿Esta seguro de actualizar las guías solicitadas?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'SI',
            cancelButtonText: 'NO'
        }).then((result) => {
            if (result.isConfirmed) {
                reduxManageNovelties(data);
                reset({
                    byTrackings: '1',
                    shipmentIds: null,
                    manageResolution: null,
                    comment: null,
                });
                setDisableSubmit(true);
            }
        });
    };

    useEffect(() => {
        if (successfulManageNoveltiesForIncidents) {
            Swal.fire(
                (masivNoveltiesManage.errorState ? "Proceso incompleto!": "Proceso exitoso!"),
                `${masivNoveltiesManage.updated} de ${masivNoveltiesManage.total} Guías/Id Externo gestionadas exitosamente. ` +
                (masivNoveltiesManage.errorState
                ? `Alguna(s) guía(s) ingresada(s) no se actualizaron. `
                : ``) ,
                (masivNoveltiesManage.errorState ? "warning" : "success")
            );
        }
    }, [successfulManageNoveltiesForIncidents]);
    
    const validGuides = () => {
        const shipmentIds = getValues('shipmentIds');
        const guides = shipmentIds.split('\n');
        const validGuide = [];
        const errors = []

        for (let i = 0; i < guides.length; i++) {
            let guide = guides[i];
            if( isNullOrUndefined(guide) ) continue;
            guide = guide.trim();
            if( validGuide.indexOf(guide) > -1 ){
                errors.push({ key: guide, message: `Guia Duplicada` });
                continue;
            }
            validGuide.push(guide);
        }
        return errors;
    }

    const validateData = (event) => {
        const shipmentIds = getValues('shipmentIds');
        const manageResolution = getValues('manageResolution');
        const comment = getValues('comment');
        if(!isNullOrUndefined(shipmentIds) && !isNullOrUndefined(manageResolution) && !isNullOrUndefined(comment)){
            setDisableSubmit(false);
        }
    }

    useEffect(() => {
        reduxGetOperators();
        reduxGetShippers();
        reduxGetNovelties();
    }, []);

    const performSearch = (data) => {
        try {
            console.log(data);
        
        clearSearchErrors("dateRange");

        if(isNullOrUndefined(data.from) || isNullOrUndefined(data.to)){
            setSearchError("dateRange", {
                type: "custom",
                message: "Rango de fechas inválido"
            })
            return;
        }
            
        
        reduxGetAddressesIncidences({
            page: 1,
            offset: offset,
            search: search,
            ...data,
        })
        } catch (error) {
            console.log(error);
            
        }
        
    }

    const changeEndDate = (dates) => {
        const [start, end] = dates;
        setValueSearch("from", DateTime.fromJSDate(start).setZone(new IANAZone("America/Bogota")).toFormat("yyyy-LL-dd"))
        setValueSearch("to", DateTime.fromJSDate(end).setZone(new IANAZone("America/Bogota")).toFormat("yyyy-LL-dd"))

        setDateRange(dates)
    };

    const columns = [
        {
            title: '#',
            render: (rowData) => {
                return <span>{rowData.idAddress}</span>;
            },
            field: 'idAddress',
            searchable: true,
            visible: true,
        },
        {
            title: 'Guía',
            render: (rowData) => {
                return <span>{rowData.trackingId}</span>;
            },
            field: 'trackingId',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-trackingid'),
        },
        {
            title: 'IdExterno',
            render: (rowData) => {
                return <span>{rowData.externalId}</span>;
            },
            field: 'externalId',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-externalid')
        },
        {
            title: 'Nombre Destinatario',
            render: (rowData) => {
                return <span>{rowData.receiverName}</span>;
            },
            visible: hasPermission('feature:rescheduling-show-column-receivername')
        },
        {
            title: 'Dirección Destinatario',
            render: (rowData) => {
                return <span>{rowData.receiverAddress}</span>;
            },
            field: 'address',
            searchable: true,
            visible: hasPermission('feature:show-recipient-address')
        },
        {
            title: 'Teléfono Destinatario',
            render: (rowData) => {
                return (
                    <span>{rowData.receiverPhone}</span>
                );
            },
            field: 'reference1',
            searchable: true,
            visible: hasPermission('feature:show-reference1-address')
        },
        {
            title: 'Estado',
            render: (rowData) => getStatusBadgeByCode(rowData.state),
            visible: hasPermission('feature:rescheduling-show-column-state')
        },
        {
            title: 'Ciudad',
            render: (rowData) => {
                return <span>{rowData.city}</span>;
            },
            field: 'city',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-city')
        },
        {
            title: 'Novedad',
            render: (rowData) => {
                return <span>{rowData.novelty}</span>;
            },
            field: 'novelty',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-novelty')
        },
        {
            title: 'Comentario Novedad/Nota',
            render: (rowData) => {
                return <span>{rowData.comment}</span>;
            },
            visible: hasPermission('feature:rescheduling-show-column-noveltynote')
        },
        {
            title: 'Valor de recaudo',
            render: (rowData) => {
                return <span>{rowData.collection}</span>;
            },
            field: 'collection',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-collection')
        },
        {
            title: 'Producto',
            render: (rowData) => {
                return rowData.product?.length > 30
                    ? <CustomTooltip data={rowData.product.split(',')} />
                    : <span>{rowData.product}</span>;
            },
            visible: hasPermission('feature:rescheduling-show-column-product')
        },
        {
            title: 'Intentos',
            render: (rowData) => (
                <span className='badge badge-pill badge-light d-flex justify-content-center align-items-center'>
                    {rowData.attempts}
                </span>
            ),
            visible: hasPermission('feature:rescheduling-show-column-attempts')
        },
        {
            title: 'Fecha Estado',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.lastUpdateDate && String(rowData.lastUpdateDate).includes('/')
                            ? DateTime.fromFormat(String(rowData.lastUpdateDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
                            : rowData.lastUpdateDate
                                ? String(rowData.lastUpdateDate)
                                : ''}
                    </span>
                );
            },
            visible: hasPermission('feature:rescheduling-show-column-lastupdatedate')
        },
        {
            title: 'Fecha Creado',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.createdDate && String(rowData.createdDate).includes('/')
                            ? DateTime.fromFormat(String(rowData.createdDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
                            : rowData.createdDate
                                ? String(rowData.createdDate)
                                : ''}
                    </span>
                );
            },
            visible: hasPermission('feature:rescheduling-show-column-createddate')
        },
        {
            title: 'Fecha Tentativa de Entrega',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.deliveryDate && String(rowData.deliveryDate).includes('/')
                            ? DateTime.fromFormat(String(rowData.deliveryDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
                            : rowData.deliveryDate
                                ? String(rowData.deliveryDate)
                                : ''}
                    </span>
                );
            },
            visible: hasPermission('feature:rescheduling-show-column-rescheduleddate')
        },
        {
            title: 'Remitente',
            render: (rowData) => {
                return <span>{rowData.shipper}</span>;
            },
            visible: hasPermission('feature:show-client-address'),
        },
        {
            title: 'Operador',
            render: (rowData) => {
                return <span>{rowData.operator}</span>;
            },
            visible: hasPermission('feature:show-operator-address')
        },
         {
           title: 'Acciones',
           render: (rowData) => {
             return (
                   <OrderManagementModal rowData={rowData} novelties={novelties} />

                    //       <>
        //         <Show when='feature:rescheduling'>
        //           <button
        //             title='Reprogramar'
        //             className='btn btn-warning btn-sm btn-circle mr-2'
        //             type='button'
        //             onClick={(e) => openReschedule(rowData)}>
        //             <i className='fas fa-calendar	fa-xs'></i>
        //           </button>
        //         </Show>
        //         <TrafficLight days={getDaysDiff(new Date(), rowData.lastUpdateDate)} />
        //       </>
             );
           },
         },
    ];

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar />
                    <div className="container-fluid">
                        <Header title={"Gestión Incidencias"} subtitle={"Módulo de gestión de Incidencias"} actionButtons={null} />
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <Tab.Container defaultActiveKey="individuales" mountOnEnter={true}>
                                <Nav variant="tabs" className="mb-3">
                                    <Nav.Item>
                                        <Nav.Link eventKey="individuales">Individuales</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="masivas">Masivas</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content value="individuales">
                                    <Tab.Pane eventKey="individuales">
                                        <form onSubmit={searchSubmit(performSearch)}>
                                            <div className='row mb-1'>
                                                <div className='form-group col-md-3'>
                                                    <label
                                                        className='form-label'>
                                                        Desde - Hasta
                                                    </label>
                                                    <Controller
                                                        control={searchControl}
                                                        name="date"
                                                        rules={{
                                                            required: "El rango de fecha es requerido"
                                                        }}
                                                        render={({ field }) => (
                                                            <>
                                                                <DatePicker
                                                                    {...field}
                                                                    selectsRange
                                                                    dateFormat="yyyy-MM-dd"
                                                                    className={'form-control'}
                                                                    maxDate={new Date()}
                                                                    shouldCloseOnSelect={false}
                                                                    isClearable
                                                                />
                                                                {
                                                                    searchErrors.date &&
                                                                    <span className="error-message">{searchErrors.date.message}</span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group col-md-3'>
                                                    <label
                                                        htmlFor='shipper'
                                                        className='form-label'>
                                                        Remitente
                                                    </label>
                                                    <Controller
                                                        control={searchControl}
                                                        name="shipper"
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    styles={customStyles}
                                                                    isClearable={true}
                                                                    options={
                                                                        shippers && Object.keys(shippers).length > 0
                                                                            ? shippers.items
                                                                                .filter((e) => e.isActive && e.country == countryUser && countryUser)
                                                                                .map((ele, key) => ({
                                                                                    value: ele.idCompany,
                                                                                    label: ele.description,
                                                                                }))
                                                                            : []
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group col-md-3'>
                                                    <label
                                                        htmlFor='operator'
                                                        className='form-label'>
                                                        Operador
                                                    </label>
                                                    <Controller
                                                        control={searchControl}
                                                        name="operator"
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    styles={customStyles}
                                                                    isClearable={true}
                                                                    options={
                                                                        logisticsOperators &&
                                                                        Object.keys(logisticsOperators).length > 0 &&
                                                                        logisticsOperators.items
                                                                            .filter((e) => e.isActive === true && e.country == countryUser && countryUser)
                                                                            .map((ele, key) => ({
                                                                                value: ele.idCompany,
                                                                                label: ele.description,
                                                                            }))
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='form-group col-md-3'>
                                                    <label
                                                        htmlFor='novelty'
                                                        className='form-label'>
                                                        Novedad
                                                    </label>
                                                    <Controller
                                                        control={searchControl}
                                                        name="novelty"
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    styles={customStyles}
                                                                    isClearable={true}
                                                                    options={
                                                                        novelties &&
                                                                        Object.keys(novelties).length > 0 &&
                                                                        novelties
                                                                            .filter((f) => f.isActive === true)
                                                                            .map((ele, key) => ({
                                                                                value: ele.code,
                                                                                label: ele.description,
                                                                            }))
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <Button
                                                    className='mr-2'
                                                    variant='primary'
                                                    type='submit'
                                                    id='btnSearch'>
                                                    Buscar
                                                </Button>
                                            </div>
                                        </form>
                                        <Grid
                                            className='stickyAddress'
                                            cols={columns}
                                            data={incidences && Object.keys(incidences).length > 0 ? incidences.items : []}
                                            total={incidences && incidences.hasOwnProperty('total') ? incidences.total : 0}
                                            page={incidences && Object.keys(incidences).length > 0 ? Number(incidences.page) : currentPage}
                                            pages={incidences && Object.keys(incidences).length > 0 ? Number(incidences.totalPages) : 0}
                                            offset={offset}
                                            defaultValue={search}
                                            onChangePage={(value) => {
                                                setCurrentPage(value)
                                                reduxGetAddressesIncidences({
                                                    page: value,
                                                    offset: offset,
                                                    search: search,
                                                });
                                            }
                                            }
                                            onChangeRange={(value) => {
                                                setOffset(value);
                                                reduxGetAddressesIncidences({
                                                    page: 1,
                                                    offset: value,
                                                    search: search,
                                                });
                                            }}
                                            onChangeSearch={(value) => {
                                                setSearch(value);
                                                reduxGetAddressesIncidences({
                                                    page: 1,
                                                    offset: offset,
                                                    search: value,
                                                });
                                            }}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content value="masivas">
                                    <Tab.Pane eventKey="masivas">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <table style={{ width: "100%" }}>
                                                <tr className="row">
                                                    <td style={{ width: "30%" }}>
                                                        <div className="form-group col-md-12">
                                                            <div className="form-check form-check-inline">
                                                                <input id="byGuia" className="form-check-input" type="radio" value="1" {...register('byTrackings')}/> 
                                                                <label className="form-check-label" htmlFor="byGuia">Guías</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input id="byAddress" className="form-check-input" type="radio" value="2" {...register('byTrackings')}/> 
                                                                <label className="form-check-label" htmlFor="byAddress">ID Externo</label>
                                                            </div>
                                                            <textarea
                                                                id="shipmentIds"
                                                                className={`form-control form-control-user ${
                                                                    errors.shipmentIds && "is-invalid"
                                                                }`}
                                                                name='shipmentIds'
                                                                {...register("shipmentIds", {
                                                                    required: true,
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9\s]*$/i,
                                                                        message: "invalid guide",
                                                                    },
                                                                })}
                                                                onChange={validateData}
                                                                rows={12}
                                                                cols={5}
                                                            />
                                                            {errors.shipmentIds && (
                                                                <span className="invalid-feedback">
                                                                    Las guías no son válidas
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="d-flex flex-column" style={{ width: "50%" }}>
                                                        <div className="form-group">
                                                            <label htmlFor="state" className="form-label">
                                                                Solución
                                                            </label>
                                                            <Controller
                                                                control={control}
                                                                defaultValue={null}
                                                                name='manageResolution'
                                                                rules={{ required: 'El campo solución es obligatorio' }}
                                                                render={({ field }) => (
                                                                <> 
                                                                    <Select
                                                                        {...field}
                                                                        name='manageResolution'
                                                                        onChange={(value) => {
                                                                            setValue('manageResolution', value || '');
                                                                            validateData(value);
                                                                        }}
                                                                        isClearable
                                                                        options={[
                                                                            { value: 1, label: 'Reprogramación' },
                                                                            { value: 2, label: 'Devolución' },
                                                                        ]}
                                                                    />
                                                                    {errors.manageResolution &&
                                                                        <span className='error-message'>{errors.manageResolution.message}</span>
                                                                    }
                                                                </>
                                                                )} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="comment" className="form-label">
                                                                Observaciones
                                                            </label>
                                                            <Controller
                                                                control={control}
                                                                name='comment'
                                                                rules={{ required: 'El campo observaciones es obligatorio' }}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            {...field}
                                                                            id="comment"
                                                                            onChange={(value) => {
                                                                                setValue('comment', value.target.value || '');
                                                                                validateData(value);
                                                                            }}
                                                                            type="text"
                                                                            className={`form-control form-control-user`}
                                                                            style={{ marginTop: "5px", marginBottom: "10px" }}
                                                                        />
                                                                        {errors.comment &&
                                                                            <span className='error-message'>{errors.comment.message}</span>
                                                                        }
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="d-flex form-group justify-content-center">
                                                            <Button
                                                                disabled={disableSubmit}
                                                                variant="primary"
                                                                type="submit"
                                                                id="btnSearch"
                                                            >
                                                                Guardar
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </form>
                                        
                                    </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        incidences: state.addressState.incidences,
        shippers: state.companyState.clients,
        logisticsOperators: state.companyState.logisticsOperators,
        novelties: state.noveltyState.novelties,
        successfulManageNoveltiesForIncidents: state.noveltyState.successfulManageNoveltiesForIncidents,
        masivNoveltiesManage: state.noveltyState.masivNoveltiesManage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAddressesIncidences: (payload) =>
            dispatch({
                type: 'FETCH_ADDRESSES_INCIDENCES_REQUEST',
                value: payload,
            }),
        reduxGetShippers: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetOperators: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
                value: payload,
            }),
        reduxGetNovelties: (payload) =>
            dispatch({
                type: 'FETCH_NOVELTIES_FOR_INCIDENTS_REQUEST',
                value: payload,
            }),
        reduxManageNovelties: (payload) =>
            dispatch({
                type: 'MANAGE_NOVELTIES_FOR_INCIDENTS_REQUEST',
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsManagement);